import React from 'react';
import { Link } from 'gatsby';

const ServicesOne = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-factory"></i>
              </div>
              <h3>Enterprise Solutions</h3>
              <p>
                Enterprise grade application development that ensures security
                and uptime
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>Startup Applications</h3>
              <p>
                All-in-one solution for startups including product research,
                design, engineering and product marketing
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>SaaS Solutions</h3>

              <p>
                Whatever online product idea you have in mind, our brilliant
                team of engineers can make it for you
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-segmentation"></i>
              </div>

              <h3>Research and Design</h3>

              <p>
                If you are just looking to groom your project idea, we got you
                covered
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>E-Commerce Platform</h3>

              <p>
                E-Commerce platform tools including landing page, product and
                category listing, payment integration, orders and inventory
                management
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-analytics"></i>
              </div>

              <h3>Analytics</h3>

              <p>
                Efficient and insightful user tracking and traffic monitoring
                for your application
              </p>

              {/* <Link className="view-details-btn" to="/service-details">
                View Details
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
