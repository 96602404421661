import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import Services from '../components/Services/Services';
import StartProject from '../components/Common/StartProject';

const ServicesPage = () => {
  return (
    <Layout title="Services">
      <Navbar />
      <PageBanner
        pageTitle="Services"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Services"
      />
      <Services />
      <StartProject />
      <Footer />
    </Layout>
  );
};

export default ServicesPage;
